<template>
  <div>
    <form v-on:submit.prevent="submitEnergy">
      <!-- CALEFACCION -->
      <div>
        <h4>Calefacción</h4>
        <div v-for="(calefaccion, index) in calefaccionesList" :key="index">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label
                  for="calefaccionTipo"
                  class="form-label col-form-label-sm"
                  >Tipo</label
                >
                <select
                  class="custom-select"
                  id="calefaccionTipo"
                  v-model="calefaccion.tipo"
                  required
                >
                  <option
                    v-for="(option, index) in calefaccionTipoArray"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <label
                for="calefaccionRendimiento"
                class="form-label col-form-label-sm"
                >Rendimiento [%]</label
              >
              <input
                v-model="calefaccion.rendimiento"
                type="number"
                step="0.01"
                class="form-control"
                id="calefaccionRendimiento"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeCalefaccion(calefaccion)"
              >
                —
              </button>
            </div>
          </div>
        </div>
        <button class="btn btn-primary" @click.prevent="addCalefaccion">
          +
        </button>
      </div>

      <!-- REFRIGERACION -->

      <div>
        <h4>Refrigeración</h4>
        <div v-for="(refrigeracion, index) in refrigeracionesList" :key="index">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label
                  for="refrigeracionTipo"
                  class="form-label col-form-label-sm"
                  >Tipo</label
                >
                <select
                  class="custom-select"
                  id="refrigeracionTipo"
                  v-model="refrigeracion.tipo"
                  required
                >
                  <option
                    v-for="(option, index) in refrigeracionTipoArray"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <label
                for="refrigeracionRendimiento"
                class="form-label col-form-label-sm"
                >Rendimiento [%]</label
              >
              <input
                v-model="refrigeracion.rendimiento"
                type="number"
                step="0.01"
                class="form-control"
                id="refrigeracionRendimiento"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeRefrigeracion(refrigeracion)"
              >
                —
              </button>
            </div>
          </div>
        </div>
        <button class="btn btn-primary" @click.prevent="addRefrigeracion">
          +
        </button>
      </div>

      <!-- ACS -->
      <div>
        <h4>ACS</h4>
        <div v-for="(acs, index) in acsList" :key="index">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label for="acsTipo" class="form-label col-form-label-sm"
                  >Tipo</label
                >
                <select
                  required
                  class="custom-select"
                  id="acsTipo"
                  v-model="acs.tipo"
                >
                  <option
                    v-for="(option, index) in acsTipoArray"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="acsRendimiento" class="form-label col-form-label-sm"
                >Rendimiento [%]</label
              >
              <input
                v-model="acs.rendimiento"
                type="number"
                step="0.01"
                class="form-control"
                id="acsRendimiento"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeAcs(acs)"
              >
                —
              </button>
            </div>
          </div>
        </div>
        <button class="btn btn-primary" @click.prevent="addAcs">
          +
        </button>
      </div>

      <!-- SECUNDARIOS -->

      <div>
        <h4>ACS Secundarios</h4>
        <div v-for="(secundario, index) in secundariosList" :key="index">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label for="secundarioTipo" class="form-label col-form-label-sm"
                  >Tipo</label
                >
                <select
                  class="custom-select"
                  id="secundarioTipo"
                  v-model="secundario.tipo"
                  required
                >
                  <option
                    v-for="(option, index) in secundarioTipoArray"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <label
                for="secundarioPotenciaInstalada"
                class="form-label col-form-label-sm"
                >Potencia instalada [kW]</label
              >
              <input
                v-model="secundario.potencia_instalada"
                type="number"
                step="0.01"
                class="form-control"
                id="secundarioPotenciaInstalada"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeSecundario(secundario)"
              >
                —
              </button>
            </div>
          </div>
        </div>

        <button class="btn btn-primary" @click.prevent="addSecundario">
          +
        </button>
      </div>

      <!-- ILUMINACION -->
      <div>
        <h4>Iluminación</h4>
        <div v-for="(iluminacion, index) in iluminacionList" :key="index">
          <div class="row">
            <div class="col-5">
              <label
                for="iluminacionSuperficie"
                class="form-label col-form-label-sm"
                >Superficie [m2]</label
              >
              <input
                v-model="iluminacion.superficie"
                type="number"
                step="0.01"
                class="form-control"
                id="iluminacionSuperficie"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-6">
              <label
                for="iluminacionPotenciaInstalada"
                class="form-label col-form-label-sm"
                >Potencia instalada [W/m2]</label
              >
              <input
                v-model="iluminacion.potencia_instalada"
                type="number"
                step="0.01"
                class="form-control"
                id="iluminacionPotenciaInstalada"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeIluminacion(iluminacion)"
              >
                —
              </button>
            </div>
          </div>
        </div>

        <button class="btn btn-primary" @click.prevent="addIluminacion">
          +
        </button>
      </div>

      <!-- TERMICA -->

      <div>
        <h4>Térmica</h4>
        <div v-for="(termica, index) in termicasList" :key="index">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label for="termicaTipo" class="form-label col-form-label-sm"
                  >Tipo</label
                >
                <select
                  class="custom-select"
                  id="termicaTipo"
                  v-model="termica.tipo"
                  required
                >
                  <option
                    v-for="(option, index) in termicaTipoArray"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="termicaCobertura" class="form-label col-form-label-sm"
                >Porcentaje de demanda cubierto [%]</label
              >
              <input
                v-model="termica.cobertura"
                type="number"
                step="0.01"
                class="form-control"
                id="termicaCobertura"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeTermica(termica)"
              >
                —
              </button>
            </div>
          </div>
        </div>

        <button class="btn btn-primary" @click.prevent="addTermica">
          +
        </button>
      </div>

      <!-- FOTOVOLTAICA -->
      <div>
        <h4>Fotovoltaicas</h4>
        <div v-for="(fv, index) in fvList" :key="index">
          <div class="row">
            <div class="col-5">
              <div class="form-group">
                <label for="fvTipo" class="form-label col-form-label-sm"
                  >Tipo</label
                >
                <select
                  required
                  class="custom-select"
                  id="fvTipo"
                  v-model="fv.tipo"
                >
                  <option
                    v-for="(option, index) in fvTipoArray"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-sm-6">
              <label for="fvCobertura" class="form-label col-form-label-sm"
                >Porcentaje de demanda cubierto [%]</label
              >
              <input
                v-model="fv.cobertura"
                type="number"
                step="0.01"
                class="form-control"
                id="fvCobertura"
                placeholder=""
                value=""
                required
              />
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger button-margin"
                @click.prevent="removeFv(fv)"
              >
                —
              </button>
            </div>
          </div>
        </div>

        <button class="btn btn-primary" @click.prevent="addFv">
          +
        </button>
      </div>

      <!-- MEDIDAS PASIVAS -->

      <div>
        <h4>Medidas pasivas</h4>
        <div v-for="(passive, index) in passiveList" :key="index">
          <div class="row mb-4">
            <div class="col-sm-11">
              <b-form-input
                v-model="passive.tipo[0]"
                :list="`my-list-id-${index}`"
                autocomplete="off"
              ></b-form-input>

              <datalist :id="`my-list-id-${index}`">
                <option
                  v-for="(choice, index) in passiveChoicesArray"
                  :key="index"
                  autocomplete="off"
                  >{{ choice }}</option
                >
              </datalist>
            </div>
            <div class="col-sm-1">
              <button
                class="btn btn-danger"
                @click.prevent="removePassive(passive)"
              >
                —
              </button>
            </div>
          </div>
        </div>
        <button class="btn btn-primary" @click.prevent="addPassive">
          +
        </button>
      </div>

      <small class="text-danger" v-if="this.submitError">
        Ha habido un error. Por favor, revise los datos.
      </small>
      <br /><br />

      <button class="btn btn-primary" type="submit">Guardar y continuar</button>
    </form>
  </div>
</template>

<script>
import api from "@/api.js";
export default {
  name: "BuildingEnergyForm",

  data: () => ({
    buildingId: undefined,
    submitError: false,

    calefaccionTipoArray: [
      {
        value: "CALDERA_ESTANDAR",
        text: "Caldera estándar",
      },
      {
        value: "CALDERA_CONDENSACION",
        text: "Caldera condensación",
      },
      {
        value: "BAJA_TEMPERATURA",
        text: "Caldera baja temperatura",
      },
      {
        value: "BOMBA DE CALOR",
        text: "Bomba de calor",
      },
      {
        value: "BOMBA_DE_CALOR_CAUDAL_VARIABLE",
        text: "Bomba de calor caudal variable",
      },
      {
        value: "EFECTO_JOULE",
        text: "Efecto Joule",
      },
      {
        value: "EQUIPO_DE_RENDIMIENTO_CONSTANTE",
        text: "Equipo rendimiento constante",
      },
    ],

    refrigeracionTipoArray: [
      {
        value: "MAQUINA_FRIGORIFICA",
        text: "Máquina frigorífica",
      },
      {
        value: "MAQUINA_FRIGORIFICA_DE_CAUDAL_VARIABLE",
        text: "Máquina frigorífica caudal variable",
      },
      {
        value: "BOMBA DE CALOR",
        text: "Bomba de calor",
      },
      {
        value: "BOMBA_DE_CALOR_CAUDAL_VARIABLE",
        text: "Bomba de calor caudal variable",
      },
      {
        value: "EQUIPO_DE_RENDIMIENTO_CONSTANTE",
        text: "Equipo rendimiento constante",
      },
    ],

    acsTipoArray: [
      {
        value: "CALDERA_ESTANDAR",
        text: "Caldera estándar",
      },
      {
        value: "CALDERA_CONDENSACION",
        text: "Caldera condensación",
      },
      {
        value: "BAJA_TEMPERATURA",
        text: "Caldera baja temperatura",
      },
      {
        value: "BOMBA DE CALOR",
        text: "Bomba de calor",
      },
      {
        value: "BOMBA_DE_CALOR_CAUDAL_VARIABLE",
        text: "Bomba de calor caudal variable",
      },
      {
        value: "EFECTO_JOULE",
        text: "Efecto Joule",
      },
      {
        value: "EQUIPO_DE_RENDIMIENTO_CONSTANTE",
        text: "Equipo rendimiento constante",
      },
    ],

    calefaccionesArray: [],

    refrigeracionesArray: [],

    acsArray: [],

    // SECUNDARIOS

    secundarioTipoArray: [
      {
        value: "BOMBEO",
        text: "Equipos de bombeo",
      },
      {
        value: "VENTILADORES",
        text: "Ventiladores",
      },
    ],

    secundariosArray: [],

    // ILUMINACION
    iluminacionArray: [],

    // TERMICA
    termicasArray: [],

    termicaTipoArray: [
      {
        value: "TERMOSOLAR_ACS",
        text: "Termosolar ACS",
      },
      {
        value: "TERMOSOLAR_CALEFACCION",
        text: "Termosolar calefacción",
      },
      {
        value: "REFRIGERACION",
        text: "Refrigeración",
      },
    ],

    // FOTOVOLTAICA

    fvArray: [],

    fvTipoArray: [
      {
        value: "ELECTRICIDAD_PARA_AUTOCONSUMO",
        text: "Electricidad para autoconsumo",
      },
      {
        value: "CALOR_RECUPERADO_PARA_ACS",
        text: "Calor recuperado para ACS",
      },
      {
        value: "CALOR_RECUPERADO_PARA_CALEFACCION",
        text: "Calor recuperado para calefacción",
      },
      {
        value: "FRIO_RECUPERADO",
        text: "Frío recuperado",
      },
    ],

    // MEDIDAS PASIVAS
    passiveArray: [],
    passiveChoicesArray: [],
  }),

  async mounted() {
    this.buildingId = this.$route.params.id;
    await this.getCalefacciones();
    await this.getRefrigeraciones();
    await this.getAcs();
    await this.getSecundarios();
    await this.getIluminacion();
    await this.getTermica();
    await this.getFv();
    await this.getPassive();
    await this.getPassiveChoices();
  },

  computed: {
    calefaccionesList() {
      return this.calefaccionesArray.filter((item) => {
        return !item.deleted;
      });
    },
    refrigeracionesList() {
      return this.refrigeracionesArray.filter((item) => {
        return !item.deleted;
      });
    },
    acsList() {
      return this.acsArray.filter((item) => {
        return !item.deleted;
      });
    },
    secundariosList() {
      return this.secundariosArray.filter((item) => {
        return !item.deleted;
      });
    },
    iluminacionList() {
      return this.iluminacionArray.filter((item) => {
        return !item.deleted;
      });
    },
    termicasList() {
      return this.termicasArray.filter((item) => {
        return !item.deleted;
      });
    },
    fvList() {
      return this.fvArray.filter((item) => {
        return !item.deleted;
      });
    },
    passiveList() {
      return this.passiveArray.filter((item) => {
        return !item.deleted;
      });
    },
  },

  methods: {
    // GETTERS
    async getCalefacciones() {
      const { data } = await api.get(`heating/?building=${this.buildingId}`);
      this.calefaccionesArray = data;
    },

    async getRefrigeraciones() {
      const { data } = await api.get(
        `refrigeration/?building=${this.buildingId}`
      );
      this.refrigeracionesArray = data;
    },

    async getAcs() {
      const { data } = await api.get(`acs/?building=${this.buildingId}`);
      this.acsArray = data;
    },

    async getSecundarios() {
      const { data } = await api.get(
        `secundary_acs/?building=${this.buildingId}`
      );
      this.secundariosArray = data;
    },

    async getIluminacion() {
      const { data } = await api.get(`lighting/?building=${this.buildingId}`);
      this.iluminacionArray = data;
    },

    async getTermica() {
      const { data } = await api.get(`thermal/?building=${this.buildingId}`);
      this.termicasArray = data;
    },

    async getFv() {
      const { data } = await api.get(
        `photovoltaic/?building=${this.buildingId}`
      );
      this.fvArray = data;
    },

    async getPassive() {
      const { data } = await api.get(`passive/?building=${this.buildingId}`);
      this.passiveArray = data;
    },

    async getPassiveChoices() {
      const { data } = await api.get(`choices/passive/`);
      this.passiveChoicesArray = data.choices;
      console.log(this.passiveChoicesArray);
    },

    // ADD AND REMOVE
    addCalefaccion() {
      this.calefaccionesArray.push({
        tipo: undefined,
        rendimiento: undefined,
      });
    },
    removeCalefaccion(calefaccion) {
      this.$set(calefaccion, "deleted", true);
      this.$set(this.calefaccionesArray, calefaccion);
    },

    addRefrigeracion() {
      this.refrigeracionesArray.push({
        tipo: undefined,
        rendimiento: undefined,
      });
    },

    removeRefrigeracion(refrigeracion) {
      this.$set(refrigeracion, "deleted", true);
      this.$set(this.refrigeracionesArray, refrigeracion);
    },

    addAcs() {
      this.acsArray.push({
        tipo: undefined,
        rendimiento: undefined,
      });
    },

    removeAcs(acs) {
      this.$set(acs, "deleted", true);
      this.$set(this.acsArray, acs);
    },

    addSecundario() {
      this.secundariosArray.push({
        tipo: undefined,
        potencia_instalada: undefined,
      });
    },

    removeSecundario(secundario) {
      this.$set(secundario, "deleted", true);
      this.$set(this.secundariosArray, secundario);
    },

    addIluminacion() {
      this.iluminacionArray.push({
        superficie: undefined,
        potencia_instalada: undefined,
      });
    },

    removeIluminacion(iluminacion) {
      this.$set(iluminacion, "deleted", true);
      this.$set(this.iluminacionArray, iluminacion);
    },

    addTermica() {
      this.termicasArray.push({
        tipo: undefined,
        cobertura: undefined,
      });
    },

    removeTermica(termica) {
      this.$set(termica, "deleted", true);
      this.$set(this.termicasArray, termica);
    },

    addFv() {
      this.fvArray.push({
        tipo: undefined,
        cobertura: undefined,
      });
    },

    removeFv(termica) {
      this.$set(termica, "deleted", true);
      this.$set(this.fvArray, termica);
    },

    addPassive() {
      this.passiveArray.push({
        tipo: [],
      });
    },

    removePassive(passive) {
      this.$set(passive, "deleted", true);
      this.$set(this.passiveArray, passive);
    },

    // SUBMITS

    async submitCalefacciones() {
      const postCalefaccionesList = this.calefaccionesArray.filter((item) => {
        return !item.id;
      });
      const putCalefaccionesList = this.calefaccionesArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteCalefaccionesList = this.calefaccionesArray.filter((item) => {
        return item.id && item.deleted;
      });

      postCalefaccionesList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.post("heating/", item);
      });

      putCalefaccionesList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`heating/${item.id}/`, item);
      });

      deleteCalefaccionesList.forEach(async (item) => {
        await api.delete(`heating/${item.id}/`);
      });
    },

    async submitRefrigeraciones() {
      const postRefrigeracionesList = this.refrigeracionesArray.filter(
        (item) => {
          return !item.id;
        }
      );
      const putRefrigeracionesList = this.refrigeracionesArray.filter(
        (item) => {
          return item.id && !item.deleted;
        }
      );
      const deleteRefrigeracionesList = this.refrigeracionesArray.filter(
        (item) => {
          return item.id && item.deleted;
        }
      );

      postRefrigeracionesList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.post("refrigeration/", item);
      });

      putRefrigeracionesList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`refrigeration/${item.id}/`, item);
      });

      deleteRefrigeracionesList.forEach(async (item) => {
        await api.delete(`refrigeration/${item.id}/`);
      });
    },

    async submitAcs() {
      const postAcsList = this.acsArray.filter((item) => {
        return !item.id;
      });
      const putAcsList = this.acsArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteAcsList = this.acsArray.filter((item) => {
        return item.id && item.deleted;
      });

      postAcsList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.post("acs/", item);
      });

      putAcsList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`acs/${item.id}/`, item);
      });

      deleteAcsList.forEach(async (item) => {
        await api.delete(`acs/${item.id}/`);
      });
    },

    async submitSecundarios() {
      const postSecundariosList = this.secundariosArray.filter((item) => {
        return !item.id;
      });
      const putSecundariosList = this.secundariosArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteSecundariosList = this.secundariosArray.filter((item) => {
        return item.id && item.deleted;
      });

      postSecundariosList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.post("secundary_acs/", item);
      });

      putSecundariosList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`secundary_acs/${item.id}/`, item);
      });

      deleteSecundariosList.forEach(async (item) => {
        await api.delete(`secundary_acs/${item.id}/`);
      });
    },

    async submitIluminacion() {
      const postIluminacionList = this.iluminacionArray.filter((item) => {
        return !item.id;
      });
      const putIluminacionList = this.iluminacionArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteIluminacionList = this.iluminacionArray.filter((item) => {
        return item.id && item.deleted;
      });

      postIluminacionList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.post("lighting/", item);
      });

      putIluminacionList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`lighting/${item.id}/`, item);
      });

      deleteIluminacionList.forEach(async (item) => {
        await api.delete(`lighting/${item.id}/`);
      });
    },

    async submitTermica() {
      const postTermicaList = this.termicasArray.filter((item) => {
        return !item.id;
      });
      const putTermicaList = this.termicasArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteTermicaList = this.termicasArray.filter((item) => {
        return item.id && item.deleted;
      });

      postTermicaList.forEach(async (item) => {
        if (item.tipo && item.cobertura) {
          item["building"] = this.buildingId;
          await api.post("thermal/", item);
        }
      });

      putTermicaList.forEach(async (item) => {
        if (item.tipo && item.cobertura) {
          item["building"] = this.buildingId;
          await api.put(`thermal/${item.id}/`, item);
        }
      });

      deleteTermicaList.forEach(async (item) => {
        await api.delete(`thermal/${item.id}/`);
      });
    },

    async submitFv() {
      const postFvList = this.fvArray.filter((item) => {
        return !item.id;
      });
      const putFvList = this.fvArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deleteFvList = this.fvArray.filter((item) => {
        return item.id && item.deleted;
      });

      postFvList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.post("photovoltaic/", item);
      });

      putFvList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`photovoltaic/${item.id}/`, item);
      });

      deleteFvList.forEach(async (item) => {
        await api.delete(`photovoltaic/${item.id}/`);
      });
    },

    async submitPassive() {
      const postPassiveList = this.passiveArray.filter((item) => {
        return !item.id;
      });
      const putPassiveList = this.passiveArray.filter((item) => {
        return item.id && !item.deleted;
      });
      const deletePassiveList = this.passiveArray.filter((item) => {
        return item.id && item.deleted;
      });

      postPassiveList.forEach(async (item) => {
        if (item.tipo[0]) {
          item["building"] = this.buildingId;
          await api.post("passive/", item);
        }
      });

      putPassiveList.forEach(async (item) => {
        item["building"] = this.buildingId;
        await api.put(`passive/${item.id}/`, item);
      });

      deletePassiveList.forEach(async (item) => {
        await api.delete(`passive/${item.id}/`);
      });
    },

    async submitEnergy() {
      try {
        await this.submitRefrigeraciones();
        await this.submitCalefacciones();
        await this.submitAcs();
        await this.submitSecundarios();
        await this.submitIluminacion();
        await this.submitTermica();
        await this.submitFv();
        await this.submitPassive();

        this.calefaccionesArray = this.calefaccionesList;
        this.refrigeracionesArray = this.refrigeracionesList;
        this.acsArray = this.acsList;
        this.secundariosArray = this.secundariosList;
        this.iluminacionArray = this.iluminacionList;
        this.termicasArray = this.termicasList;
        this.fvArray = this.fvList;
        this.passiveArray = this.passiveList;

        this.$router.push({ name: "score", params: { id: this.buildingId } });
      } catch {
        this.submitError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.button-margin {
  margin-top: 2.4rem;
}
</style>
